import React, { Component } from "react";
import { Parallax } from "react-parallax";

const imagePath = process.env.REACT_APP_SMARTLINK_IMAGE;

export default class FeaturesNewList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (


  <section className="feature-section">

    <div className="container">

      <div className="heads">

        <h3>Features</h3>
        <h4>multi-platform control</h4>
        <span className="line"></span>
      </div>

      <div className="feature_boxes row_parent">

        <div className="row flex1 mb-10">

          <div className="col_left">

            <h5>Track your fanbase the smart way</h5>
            <ul className="pointer">

              <li>Fan-tastic! - Fanify tracks Fans, people who really engage with your content and continues to
                re-engage them over time solidifying the fan-artist relationship.</li>

              <li>Real-time fanbase growth! Track the growth of your fanbase every day with Fanify’s results dashboard!
              </li>

              <li>Organic is best! Fanify doesn't only track your fanbase built with our music promotion technology but
                also all organic reach and engagement over time as well.</li>


            </ul>

          </div>

          <div className="col_right">
            <div className="img">
              <img src="../images/feature1.jpg" alt=""/>
            </div>


          </div>

        </div>


        <div className="row flex1 mb-10">

          <div className="col_left">

            <h5>World leading technology</h5>
            <ul className="pointer">

              <li>Fanify’s “New Music Audience” technology can find you fans by showing your best promotional content to
                the people who love to discover new music and like the bands and artists who sound the most like you.
              </li>

              <li>Automatic is best! We do the work so you don’t have to - Fanify’s AI driven machine learning
                technology automates the entire process of music promotion.</li>

              <li>Go back to playing music! Don't waste hundreds of hours learning advanced marketing techniques and
                complicated software - let Fanify build your fanbase for you.</li>


            </ul>


          </div>

          <div className="col_right">



            <div className="img">
              <img src="../images/feature2.jpg" alt=""/>
            </div>


          </div>

        </div>

        <div className="row flex1">

          <div className="col_left">

            <h5>Free features - free for life</h5>
            <ul className="pointer">

              <li>Share your music the smart way! - Fanify’s SmartLink lets you use one single shortened URL to share
                your music across all major streaming platforms and stores. No matter where your fans stream and buy
                their music, Fanify has you covered!</li>

              <li>Brand yourself your own way! - Fanify’s Bio Link gives you a fully customisable homepage accessed by a
                shortened URL.</li>

              <li>Connect fans to all of your content with just one link. Useful on social platforms like Instagram that
                only allow you a single link in your bio.</li>

              <li>Organic Tracking! - Fanify’s completely free smart features track your fans through all organic reach
                and engagement through these useful URLs.</li>


            </ul>

          </div>

          <div className="col_right ">

            <div className="img">
              <img src="../images/feature3.jpg" alt=""/>
            </div>


          </div>

        </div>


      </div>


    </div>
  </section>

    );
  }
}  