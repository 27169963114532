import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FeaturesList from "./features/featurenew";
import Testimonials from "./testimonials";
import { connect } from "react-redux";
import { logout } from "../../utility/token";
import Header from "../../components/shared/header/header";
import Footer from "../../components/shared/footer/footer";
import Api from "../../utility/apiHandler";
import { notification } from "antd";
import Login from "../authentication/login/login";
import Signup from "../authentication/signup/signup";
import ChangePassword from "../authentication/change-password/change-password2";

const api = new Api();

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      isLoginModalVisible: this.props.location.state?.isVerify ? true : false,
      isChangePassword: this.props.location.state?.isChangePassword ? true : false,
      isSignupModalVisible:false,
      data: [
        {
          key: "1",
          video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
          key: "2",
          video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
          key: "3",
          video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
          key: "4",
          video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
          key: "5",
          video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
          key: "6",
          video: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
      ],
      isLoading: false,
    };
  }

  openNotificationWithIcon = (type, messageType, message) => {
    notification[type]({
      message: messageType,
      description: message,
    });
  };

  makePayment = async () => {
    const { isLoggedIn, user } = this.props;
    // console.log(isLoggedIn, user);
    if (user?.dailyBudget?.subscriptionId) {
      this.openNotificationWithIcon(
        "success",
        "Success",
        "Already Subscribe Plan!"
      );
      return;
    }

    if (!isLoggedIn || !user?._id) {
      this.openNotificationWithIcon("error", "error", "You are not logged in.");
      logout(this.props.history);
      return;
    }
    if (user.premiumSubscriptionId) {
      this.props.history.push("/dashboard");
    } else if (!user.premiumSubscriptionId) {
      this.props.history.push("/smart-link");
    }
  };

  showPremiumModal = () => {
    // console.log("in");
    this.setState({ popupVisible: true });
  };

  closePopup = () => {
    this.setState({ popupVisible: false });
  };
  handleLoginModal = (bool) => { this.setState({ isLoginModalVisible : bool }) }

  handleChangePasswordModal = (bool) => { 
    this.setState({ isChangePassword: bool })}

  handleSignupModal = (bool) => { this.setState({ isSignupModalVisible: bool }) }
  
  render() {
    
    return (
      <div className="home-page">
        <Header handleLoginModal={this.handleLoginModal} handleSignupModal={this.handleSignupModal} />
        <Login isModalVisible={this.state.isLoginModalVisible} handleModal={this.handleLoginModal} handleSignupModal={this.handleSignupModal} />
        <Signup isModalVisible={this.state.isSignupModalVisible} handleModal={this.handleSignupModal} handleLoginModal={this.handleLoginModal} />
        <ChangePassword isChangePasswordModalVisible={this.state.isChangePassword} handleChangePasswordModal={this.handleChangePasswordModal} />
        <section className="home-banner">

          <img src="../images/home-banner.jpg" alt=""/>


          <div className="tag">
            <h1> AI automated music promotion</h1>
            <h2>for all artists</h2>
            <button className="btn" onClick={() => this.handleSignupModal(true)}>GET REAL FANS NOW</button>
          </div>


        </section>


  <section className="connect-section">

    <div className="container">

      <div className="heads">

        <h3>Connected</h3>
        <h4>to the music industry</h4>
        <span className="line"></span>


      </div>


      <div className="slider-logo mb-5">

        <ul className="logos owl-carousel">

          <li><img src="../images/logo/1.png" alt=""/></li>
          <li><img src="../images/logo/2.png" alt=""/></li>
          <li><img src="../images/logo/3.png" alt=""/></li>
          <li><img src="../images/logo/4.png" alt=""/></li>
          <li><img src="../images/logo/5.png" alt=""/></li>
          <li><img src="../images/logo/6.png" alt=""/></li>

        </ul>


      </div>

      <div className="connect-thumb mb-5">

        <ul className="mobile-slider owl-carousel">

          <li>

            <div className="icons"> <img src="../images/icon1.png" alt=""/></div>
            <h5>Fully Automated</h5>
            <p>Don't waste time and energy learning clunky and complicated marketing tools - Fanify takes the pain out
              of music promotion and does all the work so you don’t have to. </p>
          </li>

          <li>

            <div className="icons"> <img src="../images/icon2.png" alt=""/></div>
            <h5>AI Driven</h5>
            <p>Using AI driven machine learning, Fanify shows your music to the people who are going to LOVE it.</p>
          </li>


          <li>

            <div className="icons"> <img src="../images/icon3.png" alt=""/></div>
            <h5>A Fanbase For Life</h5>
            <p>We don’t just find the people who fall in love with your music, we keep track of them to continue showing them everything you ever create. </p>
          </li>


          <li>

            <div className="icons"> <img src="../images/icon4.png" alt=""/></div>
            <h5>New Music Audience</h5>
            <p>Fanify’s “New Music Audience” technology knows the people who love to discover new music and what music
              they love. </p>
          </li>


          <li>

            <div className="icons"> <img src="../images/icon5.png" alt=""/></div>
            <h5>Bio Link</h5>
            <p>Customize your own digital homepage.
              Showcase what you want, when you want it. </p>
          </li>


          <li>

            <div className="icons"> <img src="../images/icon6.png" alt=""/></div>
            <h5>Smart Link</h5>
            <p>Share your music the smart way.
              All streaming services with one link.</p>
          </li>


        </ul>


      </div>


      <div className="unique-box">

        <div className="heads">

          <h3>Fully Automated</h3>
          <h4>Go back to playing music and build your fanbase with Fanify.</h4>
          <span className="line"></span>


        </div>

        <div className="row  flex1">
          <div className="col_left">

            <ul className="pointer">

              <li>Intuitive and easy to use.</li>

              <li>No effort or marketing knowledge required.</li>

              <li>Start building a fanbase in 5 minutes.</li>

              <li>Five simple steps.</li>

            </ul>


          </div>

          <div className="col_right">

            <img src="../images/laptop.jpg" alt=""/>

          </div>


        </div>
      </div>

    </div>
  </section>


        <FeaturesList
          isLoggedIn={this.props.isLoggedIn}
        />




{/*

        <MarketingToolBox
          isLoggedIn={this.props.isLoggedIn}
          history={this.props.history}
          makePayment={this.makePayment}
          isLoading={this.state.isLoading}
        />
        <FeaturesList
          isLoggedIn={this.props.isLoggedIn}
        />
        <ClientTestimonials
          onClick={this.showPremiumModal}
          isLoggedIn={this.props.isLoggedIn}
          history={this.props.history}
          makePayment={this.makePayment}
          isLoading={this.state.isLoading}
        />
*/}


  <section className="why-section">

    <div className="container">

      <div className="heads">

        <h3>Why We Are Unique</h3>
        <h4>the first fully automated music promotion technology</h4>
        <span className="line"></span>
      </div>


      <div className="unque-table">

        <table className="table">
          <thead>
            <tr>
              <th scope="col"> </th>
              <th scope="col"><img src="../images/logo.png" className="table-logo" alt=""/></th>
              <th scope="col">Music marketing toolsets</th>
              <th scope="col">Pay for play playlisters</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Music promotion tools</th>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/grey-right.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
            </tr>
            <tr>
              <th scope="row">No marketing skills required</th>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/grey-right.png" width="" alt=""/></td>
            </tr>
            <tr>
              <th scope="row">Real music fans</th>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/grey-right.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
            </tr>
            <tr>
              <th scope="row">Unlimited free features</th>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
            </tr>
            <tr>
              <th scope="row">Bots and bad data</th>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/grey-right.png" width="" alt=""/></td>
            </tr>
          </tbody>
        </table>

      </div>

    </div>

  </section>

  <section className="testi-section">

    <div className="container">

      <div className="heads">

        <h3>Testimonials</h3>
        <h4>why customers love us </h4>
        <span className="line"></span>
      </div>

        <Testimonials
          onClick={this.showPremiumModal}
          isLoggedIn={this.props.isLoggedIn}
          history={this.props.history}
          makePayment={this.makePayment}
          isLoading={this.state.isLoading}
        />
    </div>

  </section>


        <Footer />
      </div>



     
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.token,
  user: state.Auth.user,
});

export default withRouter(connect(mapStateToProps)(HomePage));
