import { Button, Input, Modal, Slider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import { apiConst } from '../../../constants/api'
import Api from '../../../utility/apiHandler'
import { responseHelper } from '../../../utility/response'
import { openNotificationWithIcon } from '../../openNotification/openNotification'
import { AuthenticationMain, FormMain } from '../authentication-style'
import { defaultValidators, sliderImages } from '../authHelper'

const api = new Api()


const ChangePassword = ({isChangePasswordModalVisible, handleChangePasswordModal}) => {
    const [state, setState] = useState({
        fields: {},
        errors: {},
        isSubmitLoading: false,
        dummy: [
          {
            key: "1",
            image: `${process.env.REACT_APP_IMG_PATH}/slide-1.webp`,
          },
          {
            key: "2",
            image: `${process.env.REACT_APP_IMG_PATH}/slide-2.webp`,
          },
          {
            key: "3",
            image: `${process.env.REACT_APP_IMG_PATH}/slide-3.webp`,
          },
        ],
        isShowpassword: true,
      })
      const history = useHistory()
      const match = useRouteMatch()

      const validator = useRef(new SimpleReactValidator({
        validators: defaultValidators
      }))
      const handleShowPassword = (e) => {
        e.preventDefault()
        setState({...state, isShowpassword: !state.isShowpassword})
      }
      const handleChange = (field, e) => {
        let fields = state.fields;
    
        if (e.target.value.trim() == "") {
          fields[field] = "";
        } else {
          fields[field] = e.target.value;
        }
        setState({...state, fields });
      };

      const submit = (e) => {
        e.preventDefault();
    
        if (validator.current.allValid()) {
          if(state.fields?.password === state.fields?.confirm_password){
          setState({...state, isSubmitLoading: true });
          let data = {
            password: state.fields.password,
            token: match?.params?.token,
          };
          api
            .post(
              apiConst.resetPassword.replace(
                ":token",
                history?.location?.state?.token
              ),
              { data }
            )
            .then((response) => {
              const data = responseHelper(response, true);
              if (data && data.success) {
                openNotificationWithIcon(data?.type, data?.title, data?.message);
                handleChangePasswordModal(false)
              } else
                openNotificationWithIcon(data.type, data.type, data.message);
                setState({...state, isSubmitLoading: false });
            })
            .catch((e) => {
              openNotificationWithIcon("error", "error", "Oops Something Went Wrong");
              setState({...state, isSubmitLoading: false });
            });
          } else {
            openNotificationWithIcon("error", "error", "Passwords Should Match");
          }
        } else {
          setState({...state, errors: validator.current.getErrorMessages() });
          validator.current.showMessages();
        }
      };
      
  return (
    <>
    <Modal className='poup' centered visible={isChangePasswordModalVisible} footer={null} onCancel={() => handleChangePasswordModal(false)}>
      <img src="../images/logo.png" className="logo1" alt=""/>
        <h4>Change Password to your fanbase</h4>
      <FormMain
        onSubmit={(e) => submit(e)}
      className='login_box' >
                    
                     <div className='emails'> 
                     <Input
                        type="text"
                        value={state.fields["password"]}
                        placeholder="New Password"
                        onChange={(e) => handleChange("password",e)}
                        className={state.errors.password ? "invalid" : ""}
                      />
                      {validator.current.message(
                        "password",
                        state.fields["password"],
                        "required|password"
                      )}
                      </div>
                      <div className='password'>
                      <Input
                        type={state.isShowpassword ? "password" : "text"}
                        placeholder="Confirm New Password"
                        value={state.fields["confirm_password"]}
                        onChange={(e) => handleChange("confirm_password",e)}
                        className={state.errors.confirm_password ? "invalid" : ""}
                      />
                      <button onClick={(e) => handleShowPassword(e)}>
                          {state.isShowpassword ? 
                          <i class="fa fa-eye-slash" aria-hidden="true"></i> :
                          <i class="fa fa-eye"></i> 
                        }
                        </button>
                      {validator.current.message(
                        "confirm_password",
                        state.fields["confirm_password"],
                        "required|matchPassword"
                      )}
                    </div>
                   
                    <div style={{display: "flex", width: "100%"}}>
                          <button style={{marginRight: "10px"}}
                        type="primary"
                        htmlType="submit"
                        className="btn"
                        loading={state.isSubmitLoading}
                      >
                        CANCEL
                      </button>
                      <button
                        type="primary"
                        htmlType="submit"
                        className="btn"
                        loading={state.isSubmitLoading}
                      >
                        SUBMIT
                      </button>
                      </div>
                      <br />
                  </FormMain>
      </Modal>
    </>
  )
}

export default ChangePassword